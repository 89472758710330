import {action, makeAutoObservable} from 'mobx'
import {formatISO} from 'date-fns'
import {IStores} from './rootStore'
import ProductsStore from './ProductsStore'

export interface BillingAddress {
  address1?: string
  city?: string
  company?: string
  country?: string
  firstName?: string
  lastName?: string
  phone?: string
  zip?: string
  email?: string
  differentBillingAddress?: boolean
  salutation?: string
  invoices: string
  orderNumber: string
}

export interface ShippingAddress {
  address12?: string
  city2?: string
  company2?: string
  country2?: string
  firstName2?: string
  lastName2?: string
  phone2?: string
  zip2?: string
  email2?: string
  salutation?: string
  alreadyOrderedCO2?: boolean
}

export interface PDFData {
  Id?: number
  Number?: string
  Addresses?: [
    {
      Person: {
        Name1: string
        Name2: string
        Street: string
        ZipCode: string
        Town: string
      }
    },
  ]
  Additional?: {
    PurchaseOfficer: string
    PurchaseOrderNumber: string
  }
  Communication?: {
    CustomerEmail: string
    CustomerPhone: string
  }
  Condition?: {
    DeliveryConditionRelation: {
      Display: string
    }
    ShippingTypeRelation: {
      Display: string
    }
    PaymentConditionRelation: {
      Description: string
    }
    PaymentTypeRelation: {
      Display: string
    }
  }
  Description?: string
  Person?: {
    AddressText: string
    CustomerName1: string
    CustomerNumber: string
    CustomerStreet: string
    CustomerTown: string
    CustomerZipCode: string
  }
  Positions?: [
    {
      Additional: {
        PositionNumber: string
      }
      Item: {
        ItemDescription1: string
        ItemNumber: string
      }
      Quantity: {
        ItemQuantity: number
        PackUnitRelation: {
          ItemsPerPackage: number
        }
      }
      Price: {
        GrossPrice: number
        Price: number
        VatPercent: number
        Discount: number
        UseManualDiscount: boolean
      }
    },
  ]
  Price?: {
    Excl: number
    Incl: number
    CurrencyRelation: {
      Symbol: string
    }
  }
  ReceiptDate?: {
    PurchaseOrderDate: string
  }
}

export type CombinedAddressesType = ShippingAddress & BillingAddress

export default class CheckoutStore {
  rootStore: IStores
  productsStore: ProductsStore
  constructor(rootStore: IStores, productsStore: ProductsStore) {
    this.rootStore = rootStore
    this.productsStore = productsStore
    makeAutoObservable(this)
  }

  public isLoading = false
  public checkout = ''
  public checkoutStep = 'form'
  public orderId = ''
  public customerId = ''
  public shippingProductId = {
    ['ItemRelation']: {
      ['Symbol']: '',
    },
    ['Quantity']: {
      ['PackQuantity']: '',
    },
  }

  public co2extras = {}
  public containsCO2Products = false

  public orderSent = false
  public pdfData: PDFData = null

  public combinedAddresses: CombinedAddressesType = {
    address1: '',
    city: '',
    company: '',
    country: '',
    firstName: '',
    lastName: '',
    phone: '',
    zip: '',
    email: '',
    differentBillingAddress: false,
    address12: '',
    city2: '',
    company2: '',
    country2: '',
    firstName2: '',
    lastName2: '',
    phone2: '',
    zip2: '',
    email2: '',
    salutation: '',
    invoices: '',
    orderNumber: '',
    alreadyOrderedCO2: false,
  }

  public billingAddress: BillingAddress = {
    address1: '',
    city: '',
    company: '',
    country: '',
    firstName: '',
    lastName: '',
    phone: '',
    zip: '',
    email: '',
    differentBillingAddress: false,
    salutation: '',
    invoices: '',
    orderNumber: '',
  }

  public shippingAddress: ShippingAddress = {
    address12: '',
    city2: '',
    company2: '',
    country2: '',
    firstName2: '',
    lastName2: '',
    phone2: '',
    zip2: '',
    email2: '',
    salutation: '',
  }

  public customerContact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    salutation: '',
  }

  @action
  public async addShippingAddress(
    addressFromUserInput: CombinedAddressesType,
  ): Promise<void> {
    this.isLoading = true

    this.combinedAddresses = addressFromUserInput

    const defineShippingProductId = () => {
      const totalCartNetSum = Number(
        this.rootStore.productsStore.cart.totalPrice.amount,
      )

      if (totalCartNetSum <= 49.99) {
        this.shippingProductId = {
          ['ItemRelation']: {
            ['Symbol']: '80000110',
          },
          ['Quantity']: {
            ['PackQuantity']: '1',
          },
        }
      }

      if (totalCartNetSum >= 50 && totalCartNetSum <= 199) {
        this.shippingProductId = {
          ['ItemRelation']: {
            ['Symbol']: '80000111',
          },
          ['Quantity']: {
            ['PackQuantity']: '1',
          },
        }
      }

      if (totalCartNetSum >= 200) {
        this.shippingProductId = {
          ['ItemRelation']: {
            ['Symbol']: '80000112',
          },
          ['Quantity']: {
            ['PackQuantity']: '1',
          },
        }
      }
    }

    defineShippingProductId()

    this.billingAddress = {
      ['company']: addressFromUserInput.company,
      ['address1']: addressFromUserInput.address1,
      ['city']: addressFromUserInput.city,
      ['lastName']: addressFromUserInput.lastName,
      ['firstName']: addressFromUserInput.firstName,
      ['phone']: addressFromUserInput.phone,
      ['zip']: addressFromUserInput.zip,
      ['country']: 'Germany',
      ['orderNumber']: addressFromUserInput.orderNumber,
      ['invoices']: addressFromUserInput.invoices,
    }

    this.shippingAddress = {
      ['company2']: addressFromUserInput.company2,
      ['address12']: addressFromUserInput.address12,
      ['city2']: addressFromUserInput.city2,
      ['lastName2']: addressFromUserInput.lastName2,
      ['firstName2']: addressFromUserInput.firstName2,
      ['phone2']: addressFromUserInput.phone2,
      ['zip2']: addressFromUserInput.zip2,
    }

    this.customerContact = {
      ['email']: addressFromUserInput.email,
      ['firstName']: addressFromUserInput.firstName,
      ['lastName']: addressFromUserInput.lastName,
      ['phone']: addressFromUserInput.phone,
      ['salutation']: addressFromUserInput.salutation,
    }

    this.checkoutStep = 'summary'
    this.isLoading = false
  }

  @action
  public setCheckoutStep = (step: string) => {
    this.checkoutStep = step
  }

  @action
  public setContainsCO2Products = (val: boolean) => {
    this.containsCO2Products = val
  }

  @action
  public setCO2Extras = (
    variantSku: string | number,
    val: boolean,
    type: string,
    amountVal: number | string,
  ) => {
    const amount = `${type}-amount`
    this.co2extras[variantSku] = {
      ...this.co2extras[variantSku],
      [type]: val,
      [amount]: amountVal,
    }
  }

  @action
  public async sendOrderToSteps(): Promise<void> {
    // prod env
    const token =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiU2FsZXNmb3JjZVNURVBTUHJvemVzc2VBUElwcm9kIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQ3VsbGlnYW5fUG93ZXItVXNlciIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvdXNlcmRhdGEiOiIwIiwiZXhwIjoyMTQzMjM0ODAwLCJpc3MiOiJTdGVwIEFoZWFkIEdtYkgiLCJhdWQiOiJteVNURVBTIn0.kVf_VBoerOSQY29pBNZSEpL9_Dvu34eUewDcXReu3BQ'
    const apiURL = 'https://210472-api.stepscloud.de/rpc/order/create'
    // Prod env at Steps
    const readUrl = 'https://210472-api.stepscloud.de/rpc/order/readbyid'
    const updateUrl =
      'https://210472-api.stepscloud.de/rpc/order/additional/update'

    // // Staging env token
    // const token =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQVBJVXNlciIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IldhdGVybG9naWMgS2V5VXNlciIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvdXNlcmRhdGEiOiIwIiwiZXhwIjoyMTQ1ODI2ODAwLCJpc3MiOiJTdGVwIEFoZWFkIEdtYkgiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo1MDExLyJ9.YhHgzw6-Zn3HsjqRXH-sUUUhJVUp65DJ9ncbC8Nz7Dk'
    // // Staging env URL here
    // const apiURL = 'https://wlgapi.stepahead.de/rpc/order/create'
    // const readUrl = 'https://wlgapi.stepahead.de/rpc/order/readbyid'
    // const updateUrl = 'https://wlgapi.stepahead.de/rpc/order/additional/update'

    const salesForceAccountUrl =
      'https://waterlogic.my.site.com/services/data/v57.0/sobjects/WebshopAuftraege__c/'

    this.isLoading = true

    const positionsArray = []
    const purchaseOfficerString = `${this.customerContact.salutation} ${this.customerContact.firstName} ${this.customerContact.lastName}`
    const deliveryAdressNameString = `z.Hd. ${this.shippingAddress.firstName2} ${this.shippingAddress.lastName2}`
    const date = new Date()
    const orderDate = formatISO(date)
    const descriptionsArray: string[] = []
    const articleIdsArray: string[] = []

    this.rootStore.productsStore.cart.lineItems.forEach(item => {
      positionsArray.push({
        ['ItemRelation']: {
          ['Symbol']: item.variant.sku,
        },
        ['Quantity']: {
          ['PackQuantity']: item.quantity,
        },
        ...(this.rootStore.productsStore.cart.discountApplications.length && {
          ['Price']: {
            // TODO: dynamic discount value not hardcoded
            ['Discount1']:
              this.rootStore.productsStore.cart.discountApplications[0].value
                .percentage,
            ['UseManualDiscount']: true,
          },
        }),
      })
      articleIdsArray.push(item.variant.sku)
      descriptionsArray.push(item.title)
    })

    const composedDescription = (): string => {
      const startsWith2 = (currentArticleId: string) =>
        currentArticleId.startsWith('2')

      const allIdsStartWith2 = articleIdsArray.every(startsWith2)

      const descriptionPrefix = (): string => {
        if (allIdsStartWith2) {
          return 'ET.:'
        }
        return 'Zubeh.:'
      }

      if (descriptionsArray.length === 1) {
        return `${descriptionPrefix()} ${descriptionsArray[0]}`
      }

      if (descriptionsArray.length === 2) {
        return `${descriptionPrefix()} ${descriptionsArray[0]}, ${
          descriptionsArray[1]
        }`
      }

      return `${descriptionPrefix()} ${descriptionsArray[0]}, ${
        descriptionsArray[1]
      } ...`
    }

    const composedDescriptionString = composedDescription()

    const composedDescriptionCO2 = (): string => {
      const co2DescriptionArray: string[] = []
      this.rootStore.productsStore.cart.lineItems.forEach(item => {
        const hasCO2Extra = this.co2extras[item.variant.sku]
        const anschlussCO2 =
          hasCO2Extra && this.co2extras[item.variant.sku]['anschluss-switch']
        const ruecknahmeCO2 =
          hasCO2Extra && this.co2extras[item.variant.sku]['ruecknahme-switch']
        const withConnect2 = anschlussCO2 ? `mit` : ''
        const withConnect = anschlussCO2 ? `Anschluss` : ''
        const withReturn2 = ruecknahmeCO2 ? `und` : ''
        const withReturn = ruecknahmeCO2 ? `Abholung Leergut` : ''
        const amountConnect =
          hasCO2Extra &&
          this.co2extras[item.variant.sku]['anschluss-switch-amount']
            ? `${this.co2extras[item.variant.sku]['anschluss-switch-amount']}x`
            : ''
        const amountReturn =
          hasCO2Extra &&
          this.co2extras[item.variant.sku]['ruecknahme-switch-amount']
            ? `${this.co2extras[item.variant.sku]['ruecknahme-switch-amount']}x`
            : ''

        const builtDescription = `${item.quantity} x ${item.title} ${withConnect2} ${amountConnect} ${withConnect} ${withReturn2} ${amountReturn} ${withReturn}`

        co2DescriptionArray.push(builtDescription)
      })
      return `CO2_FLBEST: ${co2DescriptionArray.map(descStr => descStr + ' ')}`
    }

    const composedDescriptionStringCO2 = composedDescriptionCO2()

    positionsArray.push(this.shippingProductId)

    const invoiceOptions =
      this.billingAddress.invoices === 'email' ? '41-RG Mail' : '47-RG Post'

    const addressesArray = []

    if (this.shippingAddress.firstName2) {
      addressesArray.push({
        ['AddressType']: 4,
        ['Additional']: {
          ['AddressType']: 4,
          ['Defaults']: true,
        },
        ['Person']: {
          ['CountryRelation']: {
            ['Symbol']: 'DE',
          },
          ['Name1']: this.shippingAddress.company2,
          ['Name2']: deliveryAdressNameString,
          ['Street']: this.shippingAddress.address12,
          ['ZipCode']: this.shippingAddress.zip2,
          ['Town']: this.shippingAddress.city2,
        },
      })
    }

    const co2Data = {
      ['CustomerRelation']: {
        ['Symbol']: this.customerId,
      },
      ['Additional']: {
        ['PurchaseOfficer']: purchaseOfficerString,
        ['PurchaseOrderNumber']: this.billingAddress.orderNumber,
        ['Description']: composedDescriptionStringCO2,
      },
      ['Condition']: {
        ['PurchaseOrderTypeRelation']: {
          ['Symbol']: 'Webshop',
        },
        ['OrderTypeRelation']: {
          ['Symbol']: 'CO2_FLBEST',
        },
        ['ShippingTypeRelation']: {
          ['Symbol']: 'über NL durch Techniker',
        },
      },
      ['Addresses']: addressesArray,

      ['ReceiptDate']: {
        ['PurchaseOrderDate']: orderDate,
      },
      ['Optional']: {
        ['Optional9Symbol']: '21-AUF',
        ['Optional10Symbol']: 'CO2-Flasch',
      },
    }

    const zubehData = {
      ['CustomerRelation']: {
        ['Symbol']: this.customerId,
      },
      ['CountryRelation']: {
        ['Symbol']: 'DE',
      },
      ['Positions']: positionsArray,
      ['Addresses']: addressesArray,
      ['PaymentConditionRelation']: {
        ['Symbol']: '10Tne',
      },
      ['Condition']: {
        ['PurchaseOrderTypeRelation']: {
          ['Symbol']: 'Webshop',
        },
        ['OrderTypeRelation']: {
          ['Symbol']: 'ET/ZUB_END',
        },
        ['ShippingTypeRelation']: {
          ['Symbol']: 'GLS Normal',
        },
      },
      ['Additional']: {
        ['PurchaseOfficer']: purchaseOfficerString,
        ['PurchaseOrderNumber']: this.billingAddress.orderNumber,
        ['Description']: composedDescriptionString,
      },
      ['Communication']: {
        ['CustomerEmail']: this.customerContact.email,
      },
      ['ReceiptDate']: {
        ['PurchaseOrderDate']: orderDate,
      },
      ['Optional']: {
        ['Optional9Symbol']: invoiceOptions,
      },
    }

    const sendData = this.containsCO2Products ? co2Data : zubehData

    const sfObject = {
      OrderAufruf__c: JSON.stringify(sendData),
    }

    try {
      fetch(salesForceAccountUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.rootStore.userStore.userToken}`,
        },
        body: JSON.stringify(sfObject),
      }).then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return fetch(apiURL, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(sendData),
        }).then(result => {
          if (result.status === 200) {
            this.isLoading = false
            result
              .json()
              .then(res => {
                this.orderId = res.Id
                return fetch(updateUrl, {
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    ['Id']: this.orderId,
                    ['PurchaseOrderNumber']: this.billingAddress.orderNumber,
                  }),
                })
              })
              .then(() => {
                return fetch(readUrl, {
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    ['Id']: this.orderId,
                  }),
                })
              })
              .then(result => {
                if (result.status === 200) {
                  result.json().then(res => {
                    if (!this.containsCO2Products) {
                      this.pdfData = res
                    }
                    this.orderSent = true
                  })
                }
              })
            localStorage.removeItem('checkoutId')
            this.rootStore.productsStore.checkoutId = ''
            this.combinedAddresses.address12 = ''
            this.combinedAddresses.city2 = ''
            this.combinedAddresses.company2 = ''
            this.combinedAddresses.email2 = ''
            this.combinedAddresses.firstName2 = ''
            this.combinedAddresses.zip2 = ''
            this.combinedAddresses.lastName2 = ''
            this.combinedAddresses.phone2 = ''
            this.combinedAddresses.differentBillingAddress = false
            this.combinedAddresses.orderNumber = ''
            this.combinedAddresses.invoices = ''
            this.checkoutStep = 'success'
            return
          }
          this.isLoading = false
          return alert(result.statusText)
        })
      })
    } catch (error) {
      this.isLoading = false
      console.log('an error occured while trying to contact API ', error)
    }
  }
}
